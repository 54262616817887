import React, { useState, useEffect, Fragment } from 'react'
import { Card, Row, Col, Button, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { axiosApi } from '../Basicos/axiosInstances';
import { connect } from 'react-redux';
import { NewLoader, PopOverPublicar } from '../Basicos';
import * as actionsRedux from './../Store/Actions/index';
import { Link } from 'react-router-dom';
import { RiCheckboxBlankLine, RiCheckboxIndeterminateLine, RiCheckboxLine } from "react-icons/ri"
import { AiFillCheckSquare } from "react-icons/ai"
import ModalPeriodoCurso from '../Modal/ModalPeriodoCurso';
import ModalPublicado from '../Modal/ModalPublicado';
import { ContextMenu } from 'react-contextmenu';
import ModalEditarConteudo from '../Modal/ModalEditarConteudo';

function GeraOfertaPeriodoLetivo({
    ativar,
    ativarCofirmacao,
    history,
    setCursoId,
    setPeriodoLetivoId,
    cursoId,
    periodoLetivoId,
    subFuncionalidade: {
        url
    }
}) {
    const [semestres, setSemestres] = useState([]);

    const [modalConteudo, setModalConteudo] = useState(false);

    const [dadosModalConteudo, setDadosModalConteudo] = useState([]);

    const [conteudoDisponivel, setConteudoDisponivel] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const [periodosLetivos, setPeriodosLetivos] = useState([]);

    const [cursos, setCursos] = useState([]);

    const [ofertasGeradas, setOfertasGeradas] = useState();

    const [aulasAlocadas, setAulasAlocadas] = useState();

    const [isOpen, setIsOpen] = useState(false);

    const [isOpenPublicado, setIsOpenPublicado] = useState(false);

    const [dadosModalPublicado, setDadosModalPublicado] = useState({
        tema: null,
        semestre: null,
        periodoLetivo: null,
        tema_id: null
    })

    const toggleModal = () => setModal(!modal);

    const periodoLetivoSelecionado = () => periodosLetivos.find(pL => pL.id === periodoLetivoId);

    const semestresDoCursoSelecionado = () => semestres?.filter(semestre => semestre?.tema?.curso_id === cursoId);

    const cursoSelecionado = () => cursos.find(c => c.id === cursoId);

    const [modal, setModal] = useState(false);

    const gerar = (tema, turmaId) => {
        setIsLoading(true);
        axiosApi({
            method: 'post',
            url,
            data: {
                tema_id: tema.tema.id,
                periodo_letivo_id: periodoLetivoId,
                semestre_id: tema.tema.semestre_id,
                turma_id: turmaId
            }
        }).then(resp => {
            if (ofertasGeradas[periodoLetivoId] !== undefined) {
                if (turmaId != null)
                    setOfertasGeradas({
                        ...ofertasGeradas,
                        [periodoLetivoId]: [...ofertasGeradas[periodoLetivoId], tema.tema.id + "." + turmaId]
                    });
                else
                    setOfertasGeradas({
                        ...ofertasGeradas,
                        [periodoLetivoId]: [...ofertasGeradas[periodoLetivoId], tema.tema.id.toString()]
                    });
            }
            else {
                setOfertasGeradas({
                    ...ofertasGeradas,
                    [periodoLetivoId]: [tema.tema.id]
                });
            }

            ativar(201, resp.data.sucesso);
        }).catch(error => {
            if (error.response && error.response.data.erro)
                ativar(error.response.status, error.response.data.erro);
            if (error.response.status == 403)
                ativar(error.response.status, error.response.data.mensagem);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleFormSubmit = async () => {
        try {
            if (!isNaN(cursoId) && !isNaN(periodoLetivoId)) {
                const { data } = await axiosApi.get(`${url}?curso_id=${cursoId}&periodo_letivo_id=${periodoLetivoId}`)
                const { semestre, periodo, ofertasGeradas, cursos, alocadas } = data
                setSemestres(semestre);
                setPeriodosLetivos(periodo);
                setOfertasGeradas(ofertasGeradas);
                setCursos(cursos);
                setAulasAlocadas(alocadas);
            }
        } catch (error) {
            console.log(error)
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }

        }
    }

    const requisicaoInicial = async () => {
        try {
            if ((cursoId == undefined && periodoLetivoId == undefined) || (isNaN(cursoId) && isNaN(periodoLetivoId))) {
                const { data } = await axiosApi.get(url)
                const { semestre, periodo, ofertasGeradas, cursos, alocadas } = data
                setSemestres(semestre);
                setPeriodosLetivos(periodo);
                setOfertasGeradas(ofertasGeradas);
                setCursos(cursos);
                setAulasAlocadas(alocadas);
            }
        } catch (error) {
            console.log(error)
            if (error.response.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            }

        }
    }

    const toggleModalPublicado = (tema, periodoLetivoId, indexSemestre) => {
        if (tema == null && periodoLetivoId == null) {
            setDadosModalPublicado({
                tema: null,
                semestre: null,
                periodoLetivo: null
            })
        } else {
            setDadosModalPublicado({
                tema: tema.tema.nome,
                semestre: tema.tema.semestre.descricao,
                periodoLetivo: tema.semestre_periodo_letivo.periodo_letivo.descricao,
                turma: tema.semestre_periodo_letivo.turma?.descricao ?? null,
                tema_id: tema.tema.id,
                semestre_id: tema.semestre_periodo_letivo.semestre_id,
                turma_id: tema.semestre_periodo_letivo?.turma?.id ?? null,
                posicao_semestre: indexSemestre,
                semestre_periodo_letivo_id: tema.semestre_periodo_letivo_id
            })

        }
        setIsOpenPublicado(!isOpenPublicado)
    };

    const toggleSemanaPublicada = () => {
        ativar(400, "Semana Padrão já publicada!");
    }

    const toggleCardNaoAlocado = () => {
        ativar(400, "Semana Padrão sem aula alocada!");
    }

    const handleSubmitPublicado = async () => {
        try {
            await axiosApi.put(`semana-padrao/publicar/${dadosModalPublicado.tema_id}`, dadosModalPublicado);
            var semestrePeriodoIndex = semestres[dadosModalPublicado.posicao_semestre]["semestre_periodo_letivo"].findIndex(elemento => elemento.turma_id == dadosModalPublicado.turma_id);
            var semestreTemaIndex = semestres[dadosModalPublicado.posicao_semestre]["semestre_periodo_letivo"][semestrePeriodoIndex]["semestre_tema"].findIndex(elemento => elemento.tema_id == dadosModalPublicado.tema_id);
            semestres[dadosModalPublicado.posicao_semestre]["semestre_periodo_letivo"][semestrePeriodoIndex]["semestre_tema"][semestreTemaIndex].publicado = true;
            setSemestres(semestres);
        } catch (error) {
            console.log(error)
            if (error?.response?.status === 403) {
                ativar(400, error?.response?.data?.mensagem);
            } else if (error?.response?.status === 400) {
                ativar(400, error?.response?.data?.erro);
            }

        }
        toggleModalPublicado(null, null)
    }

    useEffect(() => {
        if (!periodoLetivoId || !cursoId)
            setModal(true);
        if (periodoLetivoId && cursoId)
            handleFormSubmit()
    }, [cursoId, periodoLetivoId])

    useEffect(() => { requisicaoInicial() }, [url])

    return (
        <NewLoader isActive={isLoading} overlay >
            {
                (ofertasGeradas && periodosLetivos && aulasAlocadas && cursos && periodoLetivoId && cursoId) && (
                    <Fragment>
                        <Titulo periodoLetivo={periodoLetivoSelecionado()} curso={cursoSelecionado()} toggleModal={toggleModal} />
                        <CardComSemestres
                            setIsLoading={setIsLoading}
                            url={url}
                            history={history}
                            ofertasGeradas={ofertasGeradas}
                            ativarCofirmacao={ativarCofirmacao}
                            semestresDoCurso={semestres}
                            periodoLetivoId={periodoLetivoId}
                            aulasAlocadas={aulasAlocadas}
                            toggleModalPublicado={toggleModalPublicado}
                            toggleSemanaPublicada={toggleSemanaPublicada}
                            toggleCardNaoAlocado={toggleCardNaoAlocado}
                            ativar={ativar}
                            gerar={gerar}
                            setModalConteudo={setModalConteudo}
                            setDadosModalConteudo={setDadosModalConteudo}
                            setConteudoDisponivel={setConteudoDisponivel}
                        />
                    </Fragment>
                )
            }
            {
                (periodosLetivos && cursos) && (
                    <ModalPeriodoCurso
                        isOpen={modal}
                        setIsOpen={setModal}
                        cursos={cursos}
                        periodosLetivos={periodosLetivos}
                        cursoId={cursoId}
                        setCursoId={setCursoId}
                        periodoLetivoId={periodoLetivoId}
                        setPeriodoLetivoId={setPeriodoLetivoId}
                        submit={handleFormSubmit}
                    />
                )
            }
            {
                <ModalPublicado
                    isOpen={isOpenPublicado}
                    dados={dadosModalPublicado}
                    toggleModalPublicado={toggleModalPublicado}
                    handleSubmitPublicado={handleSubmitPublicado}
                />
            }
            {
                modalConteudo ?
                    <ModalEditarConteudo
                        isOpen={modalConteudo}
                        dadosModalConteudo={dadosModalConteudo}
                        conteudoDisponivel={conteudoDisponivel}
                        setDadosModalConteudo={setDadosModalConteudo}
                        setConteudoDisponivel={setConteudoDisponivel}
                        setModalConteudo={setModalConteudo}
                    /> : ""
            }
        </NewLoader>
    );
}

const Titulo = ({ periodoLetivo, curso, toggleModal }) => {
    return (
        <h1 className="m-0 font-weight-bold label-nome-pagina">
            {periodoLetivo.descricao} - {curso.nome}
            <Button onClick={toggleModal} className="float-right">
                Editar
            </Button>
        </h1>
    );
}

//Ao gerar, isOpen confirmação
const BotaoGerar = ({ tema, nome, mensagem, gerar, ativarCofirmacao, turmaId }) => (
    <Button
        className="font-weight-bold"
        onClick={() => ativarCofirmacao({ titulo: 'gerar', mensagem: mensagem, callback: () => gerar(tema, turmaId) })}
        style={{ width: '100%', fontSize: '0.89rem' }}
    >
        {nome}
    </Button>
)

const CardComSemestres = ({
    semestresDoCurso,
    ativarCofirmacao,
    ofertasGeradas,
    aulasAlocadas,
    periodoLetivoId,
    history,
    ativar,
    gerar,
    url,
    toggleModalPublicado,
    toggleSemanaPublicada,
    toggleCardNaoAlocado,
    setIsLoading,
    setModalConteudo,
    setDadosModalConteudo,
    setConteudoDisponivel
}) => {

    const [popupVisibleItem, setPopupVisibleItem] = useState(null);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });

    const handleContextMenu = (event, index) => {
        event.preventDefault();
        setPopupVisibleItem(null);
        setPopupPosition({ x: event.clientX, y: event.clientY });
        setPopupVisibleItem(index);
    };

    const handleClickOutside = () => {
        setPopupVisibleItem(null);
    };

    useEffect(() => {
        if (popupVisibleItem !== null) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [popupVisibleItem]);

    return (
        <Card className="px-3 pt-5">
            <CardBody>
                {semestresDoCurso?.map((semestre, index) => (
                    <SemestreComTemas
                        key={index}
                        semestreComTemas={semestre}
                        ativarCofirmacao={ativarCofirmacao}
                        ofertasGeradas={ofertasGeradas}
                        aulasAlocadas={aulasAlocadas}
                        periodoLetivoId={periodoLetivoId}
                        history={history}
                        ativar={ativar}
                        gerar={gerar}
                        url={url}
                        indexSemestre={index}
                        toggleModalPublicado={toggleModalPublicado}
                        toggleSemanaPublicada={toggleSemanaPublicada}
                        toggleCardNaoAlocado={toggleCardNaoAlocado}
                        isPopupVisible={popupVisibleItem}
                        handleContextMenu={handleContextMenu}
                        popupPosition={popupPosition}
                        setIsLoading={setIsLoading}
                        setModalConteudo={setModalConteudo}
                        setDadosModalConteudo={setDadosModalConteudo}
                        setConteudoDisponivel={setConteudoDisponivel}
                    />
                ))}
            </CardBody>
        </Card>
    );
}

const SemestreComTemas = ({
    semestreComTemas,
    ativarCofirmacao,
    ofertasGeradas,
    periodoLetivoId,
    aulasAlocadas,
    history,
    ativar,
    gerar,
    url,
    toggleModalPublicado,
    toggleSemanaPublicada,
    toggleCardNaoAlocado,
    indexSemestre,
    isPopupVisible,
    handleClickOutside,
    handleContextMenu,
    setIsLoading,
    setModalConteudo,
    setDadosModalConteudo,
    setConteudoDisponivel
}) => {
    const styleAlocado = ["semestreComTemas__semestre-alocado d-flex justify-content-center align-items-center", "semestreComTemas__linha--completo", "semestreComTemasOfertas__tema--completo d-flex justify-content-center align-items-center px-2"];
    const styleGerado = ["semestreComTemas__semestre-gerado d-flex justify-content-center align-items-center", "semestreComTemas__linha--gerado", "semestreComTemasOfertas__tema--gerado d-flex justify-content-center align-items-center px-2"];

    const encontrou = aulasAlocadas.find(elemento => elemento.tema_id == ofertasGeradas[periodoLetivoId]);

    const [alocado, setAlocado] = useState(false);
    const [gerado, setGerado] = useState(true);
    const colSize = { sm: 4, md: 4, lg: 4, xl: 4 }

    // if (encontrou.tema_id == ofertasGeradas[periodoLetivoId]) {
    //     setAlocado(true);
    // }

    const onChange = (event) => {
        console.log(event.target)
    }

    const gerarConteudoVivencia = async (semestre, rota, tipoAtividadeId) => {
        try {
            setIsLoading(true);
            var todosPublicados = semestre.semestre_tema.every(tema => tema.publicado);
            var data = {
                periodo: semestreComTemas.semestre_periodo_letivo[0].periodo_letivo_id,
                semestre: semestreComTemas.semestre_periodo_letivo[0].semestre_id,
                turma: semestre.turma_id,
                tipo_atividade: tipoAtividadeId
            }
            const response = await axiosApi.post(`/gerar-${rota}`, data);

            if (tipoAtividadeId == null && rota != 'temas') {
                setConteudoDisponivel(response.data.disponiveis)
                let infos = response.data.dados;
                infos.extra = data;
                setDadosModalConteudo(infos)
            }

            var string = tipoAtividadeId == null ? response.data.mensagem
                : tipoAtividadeId == 14 ? "Vivência gerada com sucesso!"
                    : rota == 'temas' ? "Temas gerado com sucesso!" : "CHA Rodízio gerado com sucesso!"
            console.log(string);
            ativar(201, string)
        } catch (error) {
            console.log(error)
            ativar(400, error.response.data.error)
        }
        finally {
            if (tipoAtividadeId == null && rota != 'temas')
                setModalConteudo(true);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // Adiciona um event listener para detectar cliques fora do popup
        if (isPopupVisible) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isPopupVisible]);

    return (
        semestreComTemas?.semestre_periodo_letivo.length > 0 ?
            semestreComTemas?.semestre_periodo_letivo.map((semestre) => {
                return (
                    <div key={semestre.id} className="semestreComTemas d-flex align-items-center mb-5 flex-wrap">
                        <div key={semestre.id}>
                            <div key={semestre.id} onContextMenu={(event) => handleContextMenu(event, semestre.id)} className={alocado ? styleAlocado[0] : gerado ? styleGerado[0] : 'semestreComTemas__semestre d-flex justify-content-center align-items-center'}>
                                {isPopupVisible && isPopupVisible == semestre.id && (
                                    <div className='pop-up-conteudo'>
                                        <Button onClick={() => { gerarConteudoVivencia(semestre, 'conteudo', null) }}>Gerar Conteúdo</Button>
                                        {
                                            semestre.semestre_id == 6 || semestre.semestre_id == 7 ? <Button className='mt-2' onClick={() => { gerarConteudoVivencia(semestre, 'vivencia', 72) }}>Gerar CHA Rodízio</Button>
                                                : ""
                                        }
                                        <Button className='mt-2' onClick={() => { gerarConteudoVivencia(semestre, 'vivencia', 14) }}>Gerar Vivência</Button>
                                        {/* <Button className='mt-2' onClick={() => { gerarConteudoVivencia(semestre, 'temas', null) }}>Gerar Temas</Button> */}
                                    </div>
                                )}
                                <span className="font-weight-bold" style={{ fontSize: "1.5em" }}>
                                    {semestreComTemas?.descricao}
                                </span>
                            </div>
                            {semestre?.turma?.descricao}
                        </div>
                        {semestre?.semestre_tema.map((tema, index) =>
                            <QuadradoTemaSemestre
                                url={url}
                                key={index}
                                tema={tema}
                                gerar={gerar}
                                turmaId={semestre?.turma_id}
                                index={index}
                                ativar={ativar}
                                history={history}
                                alocado={alocado}
                                colSize={colSize}
                                onChange={onChange}
                                styleGerado={styleGerado}
                                styleAlocado={styleAlocado}
                                periodoLetivoId={periodoLetivoId}
                                aulasAlocadas={aulasAlocadas}
                                ofertasGeradas={ofertasGeradas}
                                ativarCofirmacao={ativarCofirmacao}
                                indexSemestre={indexSemestre}
                                toggleModalPublicado={toggleModalPublicado}
                                toggleSemanaPublicada={toggleSemanaPublicada}
                                toggleCardNaoAlocado={toggleCardNaoAlocado}
                                setIsLoading={setIsLoading} />
                        )}
                    </div>
                )
            })
            :
            <div className="semestreComTemas d-flex align-items-center mb-5 flex-wrap">
                <div>
                    <div className={alocado ? styleAlocado[0] : gerado ? styleGerado[0] : 'semestreComTemas__semestre d-flex justify-content-center align-items-center'}>
                        <span className="font-weight-bold" style={{ fontSize: "1.5em" }}>
                            {semestreComTemas?.descricao}
                        </span>
                    </div>
                </div>
            </div>
    );
}

const QuadradoTemaSemestre = ({
    ativarCofirmacao,
    ofertasGeradas,
    aulasAlocadas,
    periodoLetivoId,
    styleAlocado,
    styleGerado,
    onChange,
    colSize,
    history,
    index,
    gerar,
    ativar,
    tema,
    url,
    toggleModalPublicado,
    toggleSemanaPublicada,
    toggleCardNaoAlocado,
    indexSemestre,
    turmaId,
    setIsLoading
}) => {
    const [valorCheckbox, setValorCheckbox] = useState({
        bloqueado: false,
        publicar_professor: false,
        publicar_coordenacao: false,
        publicar_alunos: false
    });

    const onChangePublicar = (event) => {
        const { name, checked } = event.target;
        setValorCheckbox({
            ...valorCheckbox,
            [name]: checked
        })
    }

    const encontrou = aulasAlocadas.find(elemento => elemento.tema_id == tema.tema_id && elemento.periodo_letivo_id == periodoLetivoId && elemento.turma_id == turmaId);
    const [alocado, setAlocado] = useState(encontrou ? true : false);

    const styleButton = {
        width: '100%', fontSize: '0.89rem'
    }

    return ((ofertasGeradas[periodoLetivoId] && ofertasGeradas[periodoLetivoId].includes(tema?.tema.id + (turmaId == null ? '' : '.' + turmaId))) ?
        <Link to={'#'} className="semestreComTemasOfertas__temaLink" key={index}>
            <div className="d-flex justify-content-center align-items-center">
                <div className={alocado ? styleAlocado[1] : styleGerado[1]} />
                <div className={alocado ? styleAlocado[2] : styleGerado[2]}>
                    <div style={{ width: "320px" }}>
                        <div className="font-weight-bold py-2" style={{ fontSize: "1.1em" }}>
                            {tema?.tema.nome}
                        </div>
                        <Row noGutters className="py-2">
                            <Col {...colSize} className="px-1 align-content-middle">
                                <Button
                                    className="font-weight-bold btn--invertido"
                                    style={styleButton}
                                    onClick={() => history.push(`${url}/semestre/${tema?.tema.semestre.id}/tema/${tema?.tema.id}/periodo-letivo/${periodoLetivoId}/turma/${turmaId}`)}
                                >
                                    Visualizar
                                </Button>
                            </Col>
                            <Col {...colSize} className="px-1 align-content-middle">
                                <BotaoGerar
                                    ativarCofirmacao={ativarCofirmacao}
                                    gerar={gerar}
                                    tema={tema}
                                    turmaId={turmaId}
                                    nome={"Regerar"}
                                    mensagem={"Deseja refazer o calendário ?"} />
                            </Col>
                            <Col {...colSize} className="px-1 align-content-middle">
                                <Button
                                    className="font-weight-bold"
                                    style={styleButton}
                                    onClick={() => AlocarAula(url, { periodo: periodoLetivoId, tema: tema?.tema.id, turma: tema.turma_id, semestre: tema?.tema.semestre_id, turma: turmaId }, ativar, setIsLoading)}
                                >
                                    {
                                        alocado ?
                                            'Realocar' :
                                            'Alocar'
                                    }
                                </Button>
                            </Col>
                        </Row>
                        <Row noGutters>
                            <Col>
                                <input type="checkbox" name="bloqueado" onChange={onChangePublicar} checked={valorCheckbox.bloqueado} />
                                <label>Bloqueado</label>
                            </Col>
                            <Col id={`pop${tema?.tema.id}`}>
                                {
                                    tema?.publicado
                                        ? <AiFillCheckSquare onClick={() => toggleSemanaPublicada()} size="1.3rem" />
                                        : <RiCheckboxBlankLine onClick={() => alocado ? toggleModalPublicado(tema, periodoLetivoId, indexSemestre) : toggleCardNaoAlocado()} size="1.3rem" />
                                }
                                <label >Publicado</label>
                                {/* <PopOverPublicar valorCheckbox={valorCheckbox} id={tema.id} onChange={onChangePublicar} /> */}
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </Link>
        :
        <Link to={'#'} className="semestreComTemasOfertas__temaLink" key={index}>
            <div className="d-flex justify-content-center align-items-center">
                <div className="semestreComTemas__linha" />
                <div className="semestreComTemasOfertas__tema d-flex justify-content-center align-items-center px-2">
                    <div style={{ width: "320px" }}>
                        <div className="font-weight-bold py-2" style={{ fontSize: "1.1em" }}>
                            {tema?.tema.nome}
                        </div>
                        <Row noGutters className="align-content-middle py-2">
                            <Col {...colSize} className="align-content-middle">
                                <BotaoGerar ativarCofirmacao={ativarCofirmacao} turmaId={turmaId} gerar={gerar} tema={tema} nome={"Gerar"} mensagem={"Deseja gerar calendário ?"} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Link>
    )
}

const AlocarAula = async (url, dados, ativar, setIsLoading) => {
    try {
        setIsLoading(true);
        await axiosApi.post(`${url}/alocar-aulas`, dados);
        ativar(201, 'Aulas alocadas com sucesso')
    } catch (error) {
        if (error.response.data?.error == "Algo inesperado aconteceu.")
            ativar(error.response.status, error.response.data.error);
        else if (error.response.status == 403)
            ativar(error.response.status, error.response.data.mensagem);
        else if (error.response.status == 400)
            ativar(error.response.status, error.response.data.erro);
        else
            ativar(error.response.status, error.response.data.error);
    } finally {
        setIsLoading(false);
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        ativar: (tipo, acao) => dispatch(actionsRedux.ativarAlert(tipo, acao)),
        ativarCofirmacao: (obj) => dispatch(actionsRedux.ativarModalConfirm(obj)),
        setCursoId: (tipo, acao) => dispatch(actionsRedux.setCurso(tipo, acao)),
        setPeriodoLetivoId: (tipo, acao) => dispatch(actionsRedux.setPeriodoLetivo(tipo, acao))
    }
}

const mapStateToProps = (state) => {
    return {
        cursoId: state.auth.cursoId,
        periodoLetivoId: state.auth.periodoLetivoId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GeraOfertaPeriodoLetivo))